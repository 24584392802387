<template>
  <el-card class="box-card remunerationManaged full-screen">
    <div ref="pageHead" class="table-page-head">
      <el-row class="table-btns">
        <el-button type="text" icon="el-icon-edit" @click="editRole()" size="mini" class="text-btn">{{$t('addRemunerationRules')}}</el-button>
      </el-row>
    </div>
    <div class="data-table" :style="{top:queryTableHeight,height:`calc(100% - ${queryTableHeight} - 70px)`}">
      <el-table :data="remunerationList" v-loading="isloading" element-loading-background="rgba(255,255,255,0.4)" size="mini" height="100%">
        <el-table-column type="selection" fixed="left" width="50"></el-table-column>
        <el-table-column prop="name" :label="$t('ruleName')" width="180"></el-table-column>
        <!-- 业务名称 -->
        <el-table-column prop="businessName" :label="$t('businessName')" width="180"></el-table-column>
        <!--结算规则-->
        <!-- <el-table-column prop="settlementRules" :label="$t('settlementRules')" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.settlementRules==1">{{$t('proportionSettlement')}}(%)</span>
            <span v-if="scope.row.settlementRules==2">{{$t('fixedPriceSettlement')}}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="platformSettlementAmount" :label="$t('platformFee')" width="160">
          <template slot-scope="scope">
            <span>{{scope.row.platformSettlementAmount}}%</span>
          </template>
        </el-table-column>
        <el-table-column prop="sourceSettlementAmount" :label="$t('sourceFee')+1" width="160">
          <template slot-scope="scope">
            <span>{{scope.row.sourceSettlementAmount}}%</span>
          </template>
        </el-table-column>
        <el-table-column prop="outsideRules" :label="$t('sourceFee')+2" width="160">
          <template slot-scope="scope">
            {{outsideRules(scope.row)}}
          </template>
        </el-table-column>
        <el-table-column prop="remark" :label="$t('remark')" min-width="200" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createTime" :label="$t('creationTime')" width="200">
          <template slot-scope="scope">{{$manba(scope.row.createTime).format('ymdhms')}}</template>
        </el-table-column>
        <el-table-column :label="$t('extend')" fixed="right" width=" 80">
          <template slot-scope="scope">
            <el-button size="mini" @click="editRole(scope.row)" type="primary">{{$t('edit')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-drawer :title="$t('editRemunerationRules')" :visible.sync="editRoleDrawer" :direction="'rtl'" :modal="false" :destroy-on-close="true" :wrapperClosable="false" :size="500">
      <el-form :model="submitForm" :rules="rules" ref="submitForm" label-position="top">
        <el-form-item :label="$t('ruleName')" prop="name">
          <el-input size="medium" v-model="submitForm.name" />
        </el-form-item>
        <el-form-item :label="$t('businessName')" prop="remunerationCode">
          <el-select size="medium" v-model="submitForm.remunerationCode" filterable style="width:100%">
            <el-option-group v-for="group in businessList2" :key="group.name" :label="$t(group.name)">
              <el-option v-for="item in group.child" :key="item.id" :disabled="item.haveRemuneration==1" :value="item.businessCode" :label="$t(item.name)+(item.haveRemuneration==1?`（${$t('notChargeNotConfig')}）`:'')">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('platformFeeProportion')" prop="platformSettlementAmount">
          <el-input size="medium" v-model="submitForm.platformSettlementAmount" type="number" @input="inputVerify('platformSettlementAmount')">
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('sourceFeeProportion')+1" prop="sourceSettlementAmount">
          <el-input size="medium" v-model="submitForm.sourceSettlementAmount" type="number" max="100" :disabled="arr.indexOf(Number(submitForm.remunerationCode))==-1" @input="inputVerify('sourceSettlementAmount')">
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item v-if="arr.indexOf(Number(submitForm.remunerationCode))!=-1" :label="$t('sourceFeeProportion')+2" prop="sourceSettlementAmount">
          <el-input size="medium" v-model="submitForm.outsideRules" type="number" max="100" disabled>
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('remark')">
          <el-input type="textarea" size="medium" v-model="submitForm.remark" />
        </el-form-item>
        <el-form-item class="bottom-button">
          <el-button v-if="submitForm.id" size="medium" type="primary" @click="updateSubmit('submitForm')" :loading="isloading">{{$t('submit')}}</el-button>
          <el-button v-else size="medium" type="primary" @click="addSubmit('submitForm')" :loading="isloading">{{$t('submit')}}</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </el-card>
</template>
<script>
import business from "@/api/business";
import verification from "@/assets/uilt/verification"
export default {
  name: "remunerationManaged",
  data () {
    return {
      queryForm: {},
      submitForm: {},
      //table高度，动态设置
      queryTableHeight: "",

      //table显示内容
      remunerationList: [],

      //业务列表
      businessList: [],
      //显示加载中
      isloading: false,
      //新增\编辑角色
      editRoleDrawer: false,
      //设置角色权限
      editJurisdictionDrawer: false,

      //特殊业务
      arr: [20, 21, 22]
    };
  },
  computed: {
    rules () {
      let verify = new verification(this);
      return {
        name: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        remunerationCode: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' },],
        settlementRules: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        sourceSettlementAmount: [{ validator: verify.number, required: true, trigger: 'blur' }],
        platformSettlementAmount: [{ validator: verify.number, required: true, trigger: 'blur' }],
      }
    },
    /**过滤父业务元素 */
    businessList2 () {
      if (!this.businessList) return [];
      let _this = this;
      let parents = this.businessList.filter(function (t) { return t.parentId == 0 });
      parents.forEach(function (t) {
        t.child = _this.businessList.filter(function (t2) { return t.id == t2.parentId })
      })
      return parents;
    },
  },
  watch: {
    remunerationList () {
      if (this.businessList.length !== 0)
        this.$nextTick(function () { this.getBusinessName() })
    },
    businessList () {
      if (this.remunerationList.length !== 0)
        this.$nextTick(function () { this.getBusinessName() })
    },
    "submitForm.platformSettlementAmount" (l) {
      if (l > 100) l = 100;
      let s = 100 - l;
      let form = this.submitForm;
      if (this.arr.indexOf(Number(form.remunerationCode)) != -1) {
        this.$set(form, "sourceSettlementAmount", s / 2)
        this.$set(form, "outsideRules", s / 2)
      }
      else
        this.$set(form, "sourceSettlementAmount", s)
    },
    //改变业务类型时重新计算渠道酬金
    "submitForm.remunerationCode" () {
      let form = this.submitForm;
      if (this.arr.indexOf(Number(form.remunerationCode)) != -1) {
        this.$set(form, "sourceSettlementAmount", (100 - form.platformSettlementAmount) / 2)
        this.$set(form, "outsideRules", (100 - form.platformSettlementAmount) / 2)
      }
      else {
        this.$set(form, "sourceSettlementAmount", (100 - form.platformSettlementAmount))
      }
    },
    //修改渠道2酬金
    "submitForm.sourceSettlementAmount" () {
      let form = this.submitForm;
      if (this.arr.indexOf(Number(form.remunerationCode)) == -1) return;
      this.$set(form, "outsideRules", 100 - form.platformSettlementAmount - form.sourceSettlementAmount);
    }
  },
  methods: {
    getRemunerationList () {
      let _this = this;
      business.getRemunerationList({
        success: res => {
          if (res.code != 200) return;
          _this.remunerationList = res.data;
        }
      })
    },
    getBusinessList () {
      let _this = this;
      business.getList({
        success: res => {
          _this.businessList = res.data;
        }
      })
    },
    /**通过业务编号获取业务名称 */
    getBusinessName () {
      let _this = this;
      this.businessList.forEach(function (b) {
        if (b.parentId === 0) return true;
        let re = _this.remunerationList.filter(function (r) { return r.remunerationCode === b.businessCode });
        let parent = _this.businessList.find(function (b2) { return b2.id === b.parentId });

        for (let i = 0; i < re.length; i++)
          _this.$set(re[i], 'businessName', _this.$t(parent.name) + '/' + _this.$t(b.name));
      })
    },
    //其它操作
    editRole (data) {
      let _this = this;
      _this.submitForm = { settlementRules: 1 };
      if (data) {
        for (let d in data) {
          _this.submitForm[d] = data[d];
        }
      }
      _this.editRoleDrawer = true;
    },

    addSubmit (formName) {
      let _this = this;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          _this.isloading = true;
          _this.submitForm.parentId = _this.submitForm.parentId || 0;
          business.addRemuneration({
            param: _this.submitForm,
            success: res => {
              if (res.code == 200) {
                _this.$message({ type: "success", message: _this.$t('operateSuccessfully') });
                _this.getRemunerationList();
                _this.editRoleDrawer = false;
              }
              else {
                _this.$message({ type: "warning", message: _this.$t(res.msg) });
              }
              _this.isloading = false;
            }
          })
        }
      })
    },
    updateSubmit (formName) {
      let _this = this;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          _this.isloading = true;
          business.updateRemuneration({
            param: _this.submitForm,
            success: res => {
              if (res.code == 200) {
                _this.$message({ type: "success", message: _this.$t('operateSuccessfully') });
                _this.getRemunerationList();
                _this.editRoleDrawer = false;
              }
              else {
                _this.$message({ type: "warning", message: _this.$t(res.msg) });
              }
              _this.isloading = false;
            }
          })
        }
      })
    },
    /**计算 */
    inputVerify (field) {
      if (field == "platformSettlementAmount" && this.submitForm[field] > 100)
        this.submitForm[field] = 100;
      else if (field == "sourceSettlementAmount" && this.submitForm[field] > 100 - this.submitForm.platformSettlementAmount) {
        this.submitForm[field] = 100 - this.submitForm.platformSettlementAmount;
      }
    },
    /**计算特殊规则酬金 */
    outsideRules (row) {
      let v = 100 - row.platformSettlementAmount - row.sourceSettlementAmount;
      return v > 0 ? v + "%" : "";
    }
  },
  mounted () {
    let _this = this;
    this.$nextTick(() => {
      let queryTableHeight = _this.$refs.pageHead.offsetHeight + 15;
      _this.queryTableHeight = queryTableHeight + 'px';
      _this.getRemunerationList();
      _this.getBusinessList();
    })
  },
}
</script>
<style lang="less" scoped>
.tree-card {
  height: 300px;
}
</style>